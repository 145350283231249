<template>
  <span :class="['c-search-input__wrapper', styleModifier]">
    <input
      class="c-search-input"
      type="text"
      :placeholder="placeholder"
      :name="name"
    />
    <button type="submit" class="c-search-input__submit">
      <SearchIcon />
    </button>
  </span>
</template>

<script>
import SearchIcon from './SearchIcon.vue'

export default {
  name: 'InputText',
  components: {
    SearchIcon,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    styleModifier: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
// reset bleeding global styles
* {
  font-family: $baseFontFamily;
  line-height: $baseLineHeight;
}

// load craft styles
@import '@craft/styles/_vue';
@import '@craft/styles/05-components/_search-input';
</style>
