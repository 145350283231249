import initStore from './index'
import ui from './modules/ui.js'
import home from './modules/home.js'
import storeLocator from './modules/storeLocator.js'
import mixology from './modules/mixology.js'

export default initStore({
  ui,
  home,
  storeLocator,
  mixology,
})
