export const youtube = `
<svg class="icon icon-youtube" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 17">
  <path fill="currentColor" d="M23.712 1.214C24.85 2.339 25 4.126 25 8.5s-.15 6.161-1.288 7.286C22.573 16.911 21.387 17 12.5 17s-10.073-.089-11.212-1.214C.15 14.662 0 12.874 0 8.5s.15-6.161 1.288-7.286C2.427.088 3.613 0 12.5 0s10.073.089 11.212 1.214ZM10.368 12.379l6.393-3.68-6.393-3.625v7.305Z" />
</svg>
`
export const facebook = `
<svg class="icon icon-facebook" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
  <path fill="currentColor" d="M23 0H0v23h12.293v-8.895H9.3v-3.482h2.993V8.061c0-2.97 1.816-4.588 4.466-4.588.893-.003 1.785.043 2.673.134v3.105h-1.824c-1.444 0-1.725.683-1.725 1.69v2.216h3.45l-.449 3.482h-3.022V23H23V0Z" />
</svg>`

export const instagram = `
<svg class="icon icon-instagram" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
  <path fill="currentColor" d="M7.645 0C3.449 0 0 3.444 0 7.645v10.71C0 22.551 3.444 26 7.645 26h10.71C22.551 26 26 22.556 26 18.355V7.645C26 3.449 22.556 0 18.355 0H7.645Zm0 2.364h10.71a5.274 5.274 0 0 1 5.281 5.28v10.711a5.274 5.274 0 0 1-5.28 5.281H7.644a5.274 5.274 0 0 1-5.281-5.28V7.644a5.274 5.274 0 0 1 5.28-5.281ZM19.98 4.949c-.595 0-1.07.475-1.07 1.07 0 .596.475 1.072 1.07 1.072.596 0 1.071-.476 1.071-1.071 0-.596-.475-1.071-1.07-1.071Zm-6.98.96C9.1 5.91 5.91 9.1 5.91 13c0 3.9 3.19 7.09 7.09 7.09s7.09-3.19 7.09-7.09S16.9 5.91 13 5.91Zm0 2.364c2.627 0 4.727 2.1 4.727 4.727 0 2.627-2.1 4.727-4.727 4.727A4.707 4.707 0 0 1 8.273 13c0-2.627 2.1-4.727 4.727-4.727Z" />
</svg>`

export const houzz = `
<svg class="icon icon-houzz" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24">
  <path fill="currentColor" fill-rule="evenodd" d="M13.6 24h8V10.187L5.6 5.6V0H0v24h8v-8h5.6v8"/>
</svg>`

export const pinterest = `
<svg class="icon icon-pinterest" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
  <path fill="currentColor" d="M12.5 0C5.608 0 0 5.608 0 12.5c0 5.372 3.406 9.962 8.172 11.727-.137-1.224-.116-3.228.11-4.198.213-.912 1.37-5.809 1.37-5.809s-.349-.7-.349-1.734c0-1.625.942-2.838 2.114-2.838.998 0 1.479.75 1.479 1.646 0 1.003-.638 2.502-.969 3.89-.275 1.164.584 2.113 1.73 2.113 2.077 0 3.674-2.19 3.674-5.351 0-2.798-2.01-4.754-4.88-4.754-3.325 0-5.276 2.494-5.276 5.071 0 1.004.386 2.081.87 2.667a.35.35 0 0 1 .08.335c-.088.369-.285 1.162-.324 1.325-.05.215-.17.259-.39.156-1.46-.68-2.373-2.813-2.373-4.527 0-3.686 2.678-7.071 7.721-7.071 4.054 0 7.204 2.888 7.204 6.749 0 4.027-2.54 7.268-6.064 7.268-1.184 0-2.296-.615-2.677-1.342l-.729 2.778c-.246.946-1.298 2.905-1.827 3.795 1.21.39 2.497.604 3.834.604C19.393 25 25 19.393 25 12.5 25 5.608 19.393 0 12.5 0Z"/>
</svg>`

export const linkedin = `
<svg class="icon icon-linkedin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path fill="currentColor" d="M22.226 24c.98 0 1.774-.77 1.774-1.72V1.72C24 .77 23.206 0 22.226 0H1.774C.794 0 0 .77 0 1.72v20.56C0 23.23.794 24 1.774 24h20.452ZM5.463 7.773h-.024c-1.216 0-2.003-.832-2.003-1.872 0-1.063.81-1.872 2.05-1.872 1.24 0 2.004.809 2.028 1.872 0 1.04-.787 1.872-2.051 1.872Zm7.443 12.317H9.28v-.165c.004-.597.013-2.715.018-4.916l.001-.575.001-.573v-.587l.001-.289v-1.609a79.468 79.468 0 0 0-.021-2.123h3.625v1.534l.04-.06c.467-.701 1.278-1.638 2.978-1.722l.249-.006c2.385 0 4.173 1.549 4.173 4.878v6.213h-3.624v-5.797c0-1.457-.525-2.45-1.837-2.45-1.001 0-1.598.67-1.86 1.316-.096.232-.12.556-.12.88v6.051Zm-5.63 0H3.65V9.253h3.625V20.09Z" />
</svg>`

const svgMap = {
  youtube,
  facebook,
  instagram,
  houzz,
  pinterest,
  linkedin,
}

export function getSVG(key) {
  return svgMap[key]
}

export default {
  youtube,
  facebook,
  instagram,
  houzz,
  pinterest,
  linkedin,
  getSVG,
}
