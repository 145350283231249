import debug from 'debug'

let log = debug('store:home')

const home = {
  strict: true,

  namespaced: true,

  state: {
    scrollPosition: 0,
  },

  mutations: {
    SET_SCROLL_POSITION(state, position) {
      state.scrollPosition = position
    },
  },

  actions: {
    setScrollPosition(
      { commit, dispatch, state, rootState },
      { position, showHeader = true }
    ) {
      commit('SET_SCROLL_POSITION', position)
      // Close menu on scroll
      dispatch('ui/setMenuOpen', showHeader, { root: true })
    },
  },
}

export default home
