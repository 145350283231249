type BaseConfig = { [key: string]: any }

type EnvironmentConfig = {
  match: RegExp
  config: { [key: string]: any }
}

class Config {
  private defaultConfig: BaseConfig = {}
  private config: BaseConfig = {}
  private environments: EnvironmentConfig[] = []

  default(config: BaseConfig = {}) {
    this.defaultConfig = { ...this.defaultConfig, ...config }

    return this
  }

  environment(match: RegExp, config: BaseConfig = {}) {
    this.environments.push({
      match,
      config,
    })

    return this
  }

  detect() {
    const matchedConfig = this.environments.find(environment =>
      environment.match.test(window.location.href)
    )

    this.config = matchedConfig ? matchedConfig.config : this.defaultConfig

    return this
  }

  get(name = '') {
    return this.config[name] || null
  }

  set(additionalConfig: BaseConfig) {
    this.config = {
      ...this.config,
      ...additionalConfig,
    }

    return this
  }
}

export default new Config()
