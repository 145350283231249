<template lang="pug">
  a(:class="styleModifier" v-if="type === 'url'" :href="link" target="_blank" :download="download") {{ copy }}
    slot(name="embeddedCopy")
    span.c-cta__icon-wrapper(v-if="!noArrow")
      ButtonArrow
  a(:class="styleModifier" v-else-if="type === 'tel'" :href="`tel:${link}`") {{ copy }}
    slot(name="embeddedCopy")
    span.c-cta__icon-wrapper(v-if="!noArrow")
      ButtonArrow
  a(:class="styleModifier" v-else-if="type === 'email'" :href="`mailto:${link}`") {{ copy }}
    slot(name="embeddedCopy")
    span.c-cta__icon-wrapper(v-if="!noArrow")
      ButtonArrow
  a(:class="styleModifier" v-else-if="type === 'custom'" @click="method") {{ copy }}
    slot(name="embeddedCopy")
    span.c-cta__icon-wrapper(v-if="!noArrow")
      ButtonArrow
  router-link(:class="styleModifier" v-else-if="type === 'entry'" :to="`/${link}`" target="_self") {{ copy }}
    slot(name="embeddedCopy")
    span.c-cta__icon-wrapper(v-if="!noArrow")
      ButtonArrow
</template>

<script>
import ButtonArrow from '@/components/Atoms/ButtonArrow.vue'
import debug from 'debug'

let log = debug('component:CtaNew')

export default {
  name: 'cta-new',
  components: {
    ButtonArrow,
  },
  props: {
    copy: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    method: {
      type: Function,
      default: () => {
        log('No function attached')
      },
    },
    color: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'secondary', 'alternative'].includes(value)
      },
    },
    size: {
      type: String,
      default: 'm',
      validator(value) {
        return ['s', 'm', 'l'].includes(value)
      },
    },
    variant: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'outlined'].includes(value)
      },
    },
    download: {
      type: Boolean,
      default: false,
    },
    noArrow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styleModifier() {
      return `c-cta c-cta--${this.variant} c-cta--${this.color} c-cta--${
        this.size
      } ${this.noArrow ? '' : 'c-cta--arrow'}`
    },
  },
}
</script>

<style lang="scss">
// reset bleeding global styles
* {
  font-family: $baseFontFamily;
  line-height: $baseLineHeight;
}

// load craft styles
@import '@craft/styles/_vue';
@import '@craft/styles/05-components/_cta';
</style>
