import debug from 'debug'
import axios from '../../util/axios'
import { getProp } from '../../util/helpers'
import { EventBus } from '../../util/eventBus'

let log = debug('store:root')

const isMobile = () => window.innerWidth <= 750
const isTablet = () => window.innerWidth <= 980
const isIE = () => {
  const ua = window.navigator.userAgent
  const trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
  }
}

const root = {
  strict: true,

  state: {
    craftPreview: null,
    craftToken: null,
    site: null,
    loading: true,
    isIE: isIE(),
    isMobile: isMobile(),
    isTablet: isTablet(),
    pageData: {
      oops: {
        title: 'Oops',
        bodyBlocks: [
          {
            type: 'hero',
            images: [
              {
                url: require('@/assets/images/stock-images/raincloud-warehouse-brick.jpg'),
              },
            ],
            heading: 'Oops',
          },
          {
            type: 'copy',
            copy: '<h2>Sorry, something went wrong...</h2>',
          },
        ],
      },
    },
    downloads: [],
    locations: [],
    footer: {},
    nav: {},
  },

  mutations: {
    SET_CRAFT_PREVIEW(state, preview) {
      state.craftPreview = preview
    },
    SET_CRAFT_TOKEN(state, token) {
      state.craftToken = token
    },
    SET_SITE(state, site) {
      state.site = site
    },
    SET_LOADING(state, { loading }) {
      state.loading = loading
    },
    SET_PAGE(state, { endpoint, body }) {
      state.pageData[endpoint] = body
    },
    SET_GLOBALS(state, globals = { nav, footer, locations }) {
      for (let key in globals) {
        if (globals[key]) {
          state[key] = globals[key]
        }
      }
    },
    SET_LOCATION_DISTANCE(state, { index, distance }) {
      state.locations[index].distance = distance
    },
    SET_IS_MOBILE(state, isMobile) {
      state.isMobile = isMobile
    },
    SET_IS_TABLET(state, isTablet) {
      state.isTablet = isTablet
    },
    UPDATE_DOWNLOADS(state, { id, link }) {
      if (id && link) {
        state.downloads.push({ id, link })
      }
    },
  },

  getters: {
    getPageDataObject: (state) => (pageName, section) => {
      if (!state.pageData[pageName]) {
        return {}
      }

      const bodyBlock = getProp(
        state.pageData[pageName],
        'bodyBlocks',
        []
      ).find((obj) => obj.type === section)

      if (bodyBlock) {
        return bodyBlock
      }

      return getProp(state.pageData[pageName], section, [])
    },
    getSeoData: (state) => (pageName) => {
      if (
        !state.pageData[pageName] ||
        !Object.keys(state.pageData[pageName]).length
      ) {
        return {}
      }

      return getProp(state.pageData[pageName], 'head', {}).meta
    },
  },

  actions: {
    init({ commit }, site) {
      log('action init', site)
      commit('SET_SITE', site)
    },

    setLoading({ commit }, loading) {
      log('action loading', loading)

      return new Promise((resolve) => {
        commit('SET_LOADING', { loading })
        resolve(loading)
      })
    },

    getDownloadLink({ commit }, id) {
      log('getDownloadLink')
      return new Promise((resolve, reject) => {
        axios
          .get(`download.json?q=${id}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          })
          .then((response) => {
            log(response)
            return response.data
          })
          .then((body) => {
            log(`Download link: ${JSON.stringify(body)}`)
            commit('UPDATE_DOWNLOADS', { id, link: body.downloadUrl })
            EventBus.$emit(`root:getDownloadLink(${id})`)
          })
          .catch((err) => {
            log(err)
          })
      })
    },

    getFooter({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`footer.json`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          })
          .then((response) => response.data)
          .then((body) => {
            // log(`Footer data: ${JSON.stringify(body)}`)
            commit('SET_GLOBALS', { footer: body })
          })
          .catch((err) => {
            log(err)
          })
      })
    },

    getNavigation({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`nav.json`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          })
          .then((response) => response.data)
          .then((body) => {
            // log(`Nav data: ${JSON.stringify(body)}`)
            commit('SET_GLOBALS', { nav: body })
          })
          .catch((err) => {
            log(err)
          })
      })
    },

    getStoreLocations({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`locator.json`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          })
          .then((response) => response.data)
          .then((body) => {
            // log(`Footer data: ${JSON.stringify(body)}`)
            commit('SET_GLOBALS', { ...body })
          })
          .catch((err) => {
            log(err)
          })
      })
    },

    getPage({ commit, state }, endpoint) {
      return new Promise((resolve) => {
        if (state.pageData[endpoint.name]) {
          resolve(state.pageData[endpoint.name])
        } else {
          axios
            .get(endpoint.path, {
              params: {
                token: state.craftToken || '',
                'x-craft-preview': state.craftPreview || '',
              },
            })
            .then((response) => {
              // log(response.data)
              return response.data
            })
            .then((body) => {
              // log(`Page data: ${JSON.stringify(body)}`)
              commit('SET_PAGE', { endpoint: endpoint.name, body })
              resolve(body)
            })
            .catch((err) => {
              log('ERROR:' + err)
              resolve(state.pageData['oops'])
            })
        }
      })
    },

    setIsMobile({ state, commit, dispatch }) {
      const mobile = isMobile()
      if (mobile && state.isMobile != mobile) {
        dispatch('ui/setMenuOpen', false, { root: true })
      }
      commit('SET_IS_MOBILE', mobile)
    },

    setIsTablet({ commit }) {
      commit('SET_IS_TABLET', isTablet())
    },

    resetPageData({ commit, state }, page) {
      if (state.pageData[page]) {
        commit('SET_PAGE', { endpoint: page, body: null })
      }
    },
  },
}

export default root
