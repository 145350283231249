export const Page = () =>
  import(/* webpackChunkName: "Home" */ './../views/Page.vue')
export const Search = () =>
  import(/* webpackChunkName: "Home" */ './../views/Search.vue')
export const PageNotFound = () =>
  import(/* webpackChunkName: "PageNotFound" */ './../views/PageNotFound.vue')

export default {
  Page,
  Search,
}
