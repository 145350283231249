<template lang="pug">
  #app(:class="{ 'u-scroll-fix': (menuOpen && isMobile) || modalOpen }")
    navigation
    .loader(:class="{ on: loading, off: !loading }")
    router-view
    CmFooter(v-if="showFooter && !loading" :ref="'footer'")
</template>

<script>
import { throttle } from 'lodash-es'
import debug from 'debug'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { EventBus } from './util/eventBus'
import { scrollTo } from './util/scroll'
import { getProp } from './util/helpers'

import Navigation from './components/Navigation'
import CmFooter from './components/CmFooter'

new Vue()
const log = debug('app')

export default Vue.extend({
  name: 'app',
  data() {
    return {
      color: '#A0A3AA',
      size: '200px',
      height: '100px',
      width: '20px',
    }
  },
  mounted() {
    log('App Mounted', this.$parent.site)
    this.setIsMobileThrottled = throttle(this.setIsMobile, 100)
    this.setIsTabletThrottled = throttle(this.setIsTablet, 100)
    window.addEventListener('resize', this.setIsMobileThrottled)
    window.addEventListener('resize', this.setIsTabletThrottled)
  },
  components: {
    CmFooter,
    Navigation,
  },
  beforeCreate() {
    this.$store.dispatch('getFooter')
    this.$store.dispatch('getNavigation')
  },
  created() {
    this.listenerScrollToFooter()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setIsMobileThrottled)
    window.removeEventListener('resize', this.setIsTabletThrottled)
  },
  computed: {
    ...mapState('ui', [
      'showFooter',
      'menuOpen',
      'scrollToFooter',
      'modalOpen',
    ]),
    ...mapState(['site', 'loading', 'isMobile']),
  },
  methods: {
    ...mapActions('ui', ['setScrollToFooter']),
    ...mapActions(['setIsMobile', 'setIsTablet']),
    listenerScrollToFooter() {
      EventBus.$on('ui:scrollToFooter', () => {
        this.appScrollToFooter()
      })
    },
    appScrollToFooter() {
      let footer = getProp(this.$refs.footer, '$el')

      if (footer) {
        scrollTo(footer, this.$el, {})
        this.setScrollToFooter(false)
      }
    },
  },
  watch: {
    showFooter() {
      if (this.scrollToFooter) {
        this.$nextTick(this.appScrollToFooter)
      }
    },
  },
})
</script>

<style lang="scss">
@import './styles/main';

#app {
  width: 100%;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity $transitionFast;
    z-index: 1;
    pointer-events: none;
  }

  &.u-scroll-fix {
    max-height: 100vh;
    overflow: hidden;

    &::before {
      opacity: 1;
      pointer-events: all;
    }
  }

  .loader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: white;
    z-index: z-index(loader);
    transition: opacity $transitionSlow;

    &.on {
      opacity: 1;
      pointer-events: all;
    }
    &.off {
      opacity: 0;
      pointer-events: none;
    }
  }
}
</style>
