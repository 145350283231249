import debug from 'debug'
import Config from '@/config/config'

const log = debug('helper:GTM')

/**
 * @function gtm Helper function to push information to the GTM data layer
 *
 * @param {string} eventName Name of the custom event
 * @param {object} options Any additional propertys to include in the GTM push
 */

class GTM {
  gtm (eventName, options = {}) {
    log('DataLayer', `event ${eventName}`, options)
    if (Config.get('isProduction') === true) {
      window.dataLayer.push({
        event: eventName,
        ...options,
      })
    }
  }
  trackClick (eventCategory, eventLabel, options = {}) {
    this.gtm('interaction', {
      'target-properties': eventLabel,
      'target': eventCategory,
      ...options,
    })
  }
  trackPageView (pageName, path = null) {
    this.gtm('content-view', {
      'content-name': path,
      'content-view-name': pageName,
    })
  }
}

export default new GTM()
