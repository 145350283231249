<template lang="pug">
  .cta
    a(v-if="type === 'url'" :href="link" target="_blank") {{ copy }}
      slot(name="embeddedCopy")
    a(v-if="type === 'tel'" :href="`tel:${link}`") {{ copy }}
      slot(name="embeddedCopy")
    a(v-if="type === 'email'" :href="`mailto:${link}`") {{ copy }}
      slot(name="embeddedCopy")
    a(v-if="type === 'custom'" @click="method") {{ copy }}
      slot(name="embeddedCopy")
    router-link(v-if="type === 'entry'" :to="`/${link}`" target="_self") {{ copy }}
      slot(name="embeddedCopy")
</template>

<script>
import debug from 'debug'

let log = debug('component:Cta')

export default {
  name: 'cta',
  props: {
    copy: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    method: {
      type: Function,
      default: () => {
        log('No function attached')
      },
    },
  },
}
</script>

<style lang="scss">
.cta a:hover {
  .shopping-cart-wrapper .cart-icon {
    stroke: white;
  }
}
.cta .shopping-cart-wrapper .cart-icon:hover {
  a {
    color: white;
  }
}
</style>
