import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

export const scrollTo = (
  element,
  context = window,
  { horizontal = false, duration = 1, offset = 0 }
) => {
  gsap.registerPlugin(ScrollToPlugin)
  const scrollOptions = horizontal ? { x: element } : { y: element }
  scrollOptions.autoKill = false
  scrollOptions.offsetY = horizontal ? 0 : offset
  scrollOptions.offsetX = horizontal ? offset : 0

  gsap.to(context, {
    duration,
    ease: 'power4.out',
    scrollTo: scrollOptions,
  })
}
