import initRouter from './index'
import { Page, Search } from './views'
import GTM from '../util/gtm'
import debug from 'debug'
import Config from '../config/config'

const log = debug('router')

/**
 * @function defineRouter
 *
 * @param {Store} store Instance of Vuex Store; used to sync router to correct store
 */
export default function defineRouter(store) {
  const routes = [
    {
      path: '/search',
      name: 'search',
      component: Search,
      meta: {
        feeder: 'search.json',
      },
    },
    {
      path: '/:slug',
      name: 'page',
      component: Page,
      meta: {
        feeder: 'page/:slug.json',
      },
    },
    {
      path: '/product/:slug',
      name: 'product',
      component: Page,
      meta: {
        feeder: 'product/:slug.json',
      },
    },
  ]

  const beforeEach = (to, from, next) => {
    store.dispatch('ui/resetUi', { root: true })
    if (to.meta?.handledByCraft) {
      log(`Route handled by Craft CMS, redirecting to ${to.path}`)
      window.location = to.path
      return
    }
    let pageName = to.params.slug || to.name
    if (!Config.get('isLocal') && pageName) {
      GTM.trackPageView(pageName, to.path)
    }
    if (to.name === 'home') {
      store.dispatch('ui/setShowFooter', false, { root: true })
      next()
    } else if (to.name === 'search') {
      let path = to.meta.feeder

      if (to.fullPath.includes('?')) {
        const fullPathSplit = to.fullPath.split('?')
        const queryString = `?${fullPathSplit[1]}`
        path = path + queryString
      }
      store.dispatch('resetPageData', 'search')
      store.dispatch('setLoading', true)
      store.dispatch('getPage', { name: to.name, path }).then(() => {
        next()
      })
    } else {
      if (!store.state.pageData[to.params.slug]) {
        store.dispatch('setLoading', true)
      }
      next()
    }
  }

  return initRouter(routes, store, { beforeEach })
}
