import Config from './base-config'

const production = {
  isLocal: false,
  isProduction: true,
  apiBase: '/api/',
}

const stage = {
  ...production,
  isLocal: true,
  isProduction: false,
}

const dev = {
  ...production,
  isLocal: true,
  isProduction: false,
}

// initialize config
Config.default(production)
  .environment(/stage/, { ...stage })
  .environment(/\.ddev\.site/, { ...dev })
  .detect()

export default Config
