var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{ref:"navigation",staticClass:"navigation",class:{
    'is-active': _vm.menuOpen,
    'is-search-active': _vm.searchOpen,
    'is-fixed': _vm.isFixed,
    'is-fixing': _vm.isFixing,
  },attrs:{"id":"navigation"}},[_c('a',{attrs:{"href":_vm.navigation.siteUrl}},[_c('picture',[_c('source',{attrs:{"media":"(max-width: 980px)","srcset":require('@/assets/images/cmines-logo-mobile.svg')}}),_c('img',{staticClass:"navigation__logo",attrs:{"src":require('@/assets/images/cmines-logo.svg'),"alt":_vm.navigation.siteName}})])]),_c('div',{staticClass:"navigation__menu-wrapper",attrs:{"id":"main-navigation"}},[_c('ul',{staticClass:"navigation__menu-list"},_vm._l((_vm.navEntries),function(menuItem,index){return _c('li',{key:`navEntry-${index}`},[_c('cta',_vm._b({staticClass:"navigation__menu-item"},'cta',{ ...menuItem },false))],1)}),0),_c('div',{staticClass:"navigation__buttons-wrapper"},_vm._l((_vm.navCTAs),function(menuItem,index){return _c('cta-new',_vm._b({key:`navCTA-${index}`,staticClass:"c-cta--nw"},'cta-new',{
          ...menuItem,
          size: 's',
          color: 'alternative',
          noArrow: true,
          variant: menuItem.isCTA,
        },false))}),1)]),_c('form',{staticClass:"navigation__search-wrapper",attrs:{"action":"/search"}},[_c('input-text',{staticClass:"navigation__search-input",attrs:{"placeholder":'Search',"name":'q'}})],1),_c('button',{staticClass:"navigation__button-icon navigation__search",attrs:{"aria-label":"Toggle Search Bar"},on:{"click":_vm.toggleSearch}},[_c('SearchIcon'),_c('CloseIcon')],1),_c('button',{staticClass:"navigation__button-icon navigation__hamburger",on:{"click":_vm.toggleMenu}},[_c('HamburgerIcon'),_c('CloseIcon')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }