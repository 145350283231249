import debug from 'debug'
import { EventBus } from '../../util/eventBus'

let log = debug('store:ui')

const topOfPage = () => document.getElementById('app').scrollTop === 0

const ui = {
  strict: true,

  namespaced: true,

  state: {
    carouselIndex: {
      colorCarousel: 0,
      default: 0,
      instagramCarousel: 0,
    },
    filterIndex: {
      default: {
        activeIndex: 0,
        activeSubCatIndex: -1,
        isOpen: false,
      },
    },
    initialState: true,
    menuOpen: false,
    modalOpen: false,
    scrollToFooter: false,
    searchOpen: false,
    showFooter: false,
    showScrollArrows: true,
    topOfPage: topOfPage(),
  },

  mutations: {
    SET_CAROUSEL_INDEX(state, { key, value }) {
      state.carouselIndex[key] = value
    },
    SET_FILTER_CATEGORY_INDEX(state, { key, value }) {
      if (!state.filterIndex[key]) {
        state.filterIndex[key] = {}
      }
      state.filterIndex[key].activeIndex = value
    },
    SET_FILTER_SUBCATEGORY_INDEX(state, { key, value }) {
      if (!state.filterIndex[key]) {
        state.filterIndex[key] = {}
      }
      state.filterIndex[key].activeSubCatIndex = value
    },
    SET_FILTER_IS_OPEN(state, { key, value }) {
      if (!state.filterIndex[key]) {
        state.filterIndex[key] = {}
      }
      state.filterIndex[key].isOpen = value
    },
    SET_SCROLL_TO_FOOTER(state, scrollToFooter) {
      state.scrollToFooter = scrollToFooter
    },
    SET_SHOW_FOOTER(state, showFooter) {
      state.showFooter = showFooter
    },
    SET_INITIAL_STATE(state, initialState) {
      state.initialState = initialState
    },
    SET_SHOW_SCROLL_ARROWS(state, show) {
      state.showScrollArrows = show
    },
    SET_MENU_OPEN(state, show) {
      state.menuOpen = show
    },
    SET_MODAL_OPEN(state, show) {
      state.modalOpen = show
    },
    SET_SEARCH_OPEN(state, show) {
      state.searchOpen = show
    },
    SET_TOP_OF_PAGE(state, show) {
      state.topOfPage = show
    },
  },

  getters: {
    getCarouselIndex: (state) => (key) => {
      return state.carouselIndex[key]
    },
    categoryIndex: (state) => (key) => {
      return state.filterIndex[key].activeIndex
    },
    subcategoryIndex: (state) => (key) => {
      return state.filterIndex[key].activeSubCatIndex
    },
    isFilterOpen: (state) => (key) => {
      return state.filterIndex[key].isOpen
    },
  },

  actions: {
    setScrollToFooter({ commit }, scrollToFooter) {
      if (scrollToFooter) {
        EventBus.$emit('ui:scrollToFooter')
      }
      commit('SET_SCROLL_TO_FOOTER', scrollToFooter)
    },
    setShowFooter({ commit }, showFooter) {
      commit('SET_SHOW_FOOTER', showFooter)
    },
    setInitialState({ commit }, initialState) {
      commit('SET_INITIAL_STATE', initialState)
    },
    resetCarouselIndeces({ state, commit }) {
      const carouselKeys = Object.keys(state.carouselIndex)
      for (let key of carouselKeys) {
        commit('SET_CAROUSEL_INDEX', { key, value: 0 })
      }
    },
    resetUi({ rootState, commit, dispatch }) {
      commit('SET_SHOW_FOOTER', true)
      commit('SET_INITIAL_STATE', false)
      commit('SET_SHOW_SCROLL_ARROWS', false)
      commit('SET_SCROLL_TO_FOOTER', false)
      commit('SET_MENU_OPEN', false)
      commit('SET_SEARCH_OPEN', false)
      commit('SET_TOP_OF_PAGE', true)
      dispatch('resetCarouselIndeces')
    },
    setMenuOpen({ commit }, showMenu) {
      commit('SET_MENU_OPEN', showMenu)
      if (showMenu) {
        commit('SET_SEARCH_OPEN', false)
      }
    },
    setSearchOpen({ commit }, showSearch) {
      commit('SET_SEARCH_OPEN', showSearch)
      if (showSearch) {
        commit('SET_MENU_OPEN', false)
      }
    },
    setTopOfPage({ commit }) {
      commit('SET_TOP_OF_PAGE', topOfPage())
    },
  },
}

export default ui
