<template>
  <footer class="c-footer" v-once>
    <img
      class="c-footer__logo"
      :src="require('@/assets/images/cmines-logo.svg')"
      alt=""
    />
    <div class="c-footer__content-wrapper">
      <div
        class="c-footer__content-description"
        v-html="first(getProp(footer, 'legal', []), {}).copyrightCopy || ''"
      ></div>
      <ul class="c-footer__links" v-if="legalLinks.length">
        <li v-for="(link, i) in legalLinks" :key="i">
          <router-link class="c-footer__link" :to="link.url">{{
            link.label
          }}</router-link>
        </li>
      </ul>
    </div>
    <p class="c-footer__newsletter" v-if="newsletter">
      <a :href="newsletter.url" v-bind="{ target: newsletter.target }">{{
        newsletter.label
      }}</a>
    </p>
    <div class="c-footer__social-wrapper">
      <a
        v-for="sl in socialLinks"
        :href="sl.link"
        :key="sl.icon"
        class="c-footer__social-icon"
        v-html="getSVG(sl.icon)"
        :title="sl.icon"
      >
      </a>
    </div>
  </footer>
</template>

<script>
import debug from 'debug'
import { mapState } from 'vuex'
import { first, getProp } from '@/util/helpers'
import { getSVG } from '@/store/data/social/socialMediaIcons'

let log = debug('component:CmFooter')

export default {
  name: 'cmFooter',
  components: {},
  mounted() {
    log('Mounted')
  },
  computed: {
    ...mapState(['footer']),
    legalLinks() {
      const labels = ['Privacy Policy', 'Color Disclaimer', 'Warranty']
      return Object.values(
        this.first(this.getProp(this.footer, 'legal', []), {}).legalLinks
      ).map((l, idx) => ({ label: labels[idx], url: l }))
    },
    socialLinks() {
      return this.first(this.getProp(this.footer, 'social', []), {}).socials
    },
    newsletter() {
      return this.getProp(this.footer, 'newsletter', null)
    },
  },
  methods: {
    first,
    getProp,
    getSVG,
  },
}
</script>

<style lang="scss">
// reset bleeding global styles
* {
  font-family: $baseFontFamily;
  line-height: $baseLineHeight;
}

p {
  margin: unset;
  color: inherit;
  line-height: unset;
}
// load craft styles
@import '@craft/styles/_vue';
@import '@craft/styles/05-components/_footer';
</style>
