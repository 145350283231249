import debug from 'debug'

let log = debug('store:mixology')

const MAX = 4

const mixology = {
  strict: true,

  namespaced: true,

  state: {
    categoryIndex: -1,
    colorIndex: -1,
    indexToRemove: -1,
    max: MAX,
    productData: {},
    productIndex: -1,
    selectedProducts: Array.from({ length: MAX }, () => null),
  },

  mutations: {
    SET_PRODUCT_DATA(state, productData) {
      state.productData = productData
    },
    SET_CATEGORY_INDEX(state, index) {
      state.categoryIndex = index
    },
    SET_PRODUCT_INDEX(state, index) {
      state.productIndex = index
    },
    SET_COLOR_INDEX(state, index) {
      state.colorIndex = index
    },
    ADD_PRODUCT(state, { index, product }) {
      state.selectedProducts[index] = product
    },
    REMOVE_PRODUCT(state) {
      state.selectedProducts[state.indexToRemove] = null
    },
    RESET_PRODUCTS(state) {
      state.selectedProducts = Array.from({ length: MAX }, () => null)
    },
    SET_REMOVE_INDEX(state, index) {
      state.indexToRemove = index
    },
  },

  getters: {
    getProductId: (state) => (colorIndex) => {
      if (isNaN(colorIndex)) {
        colorIndex = state.colorIndex
      }
      const category = state.productData[state.categoryIndex]
      const product = category.products[state.productIndex]
      const color = product.colors[colorIndex] || {}

      return `${category.name}-${category.subCat}-${product.title}-${color.name}`
    },
    hasSelectedProduct: (state) => (id) => {
      return state.selectedProducts.some(product => {
        return product && product.id === id
      })
    },
    currentCategory(state) {
      return state.productData[state.categoryIndex]
    },
    currentColor(state) {
      return state.productData[state.categoryIndex].products[state.productIndex].colors[state.colorIndex]
    },
    currentProduct(state) {
      return state.productData[state.categoryIndex].products[state.productIndex]
    },
    isSelected: (state) => (colorIndex) => {
      return product.id === `${getters.currentCategory.name}-${getters.currentCategory.subCat}-${getters.currentProduct.title}-${state.productData[state.categoryIndex].products[state.productIndex].colors[colorIndex]}`
    },
  },

  actions: {
    setNextProduct({ commit, state, getters }) {
      if (state.colorIndex < 0 || state.productIndex < 0 || state.categoryIndex < 0) {
        return
      }

      const product = {
        id: getters.getProductId(),
        category: getters.currentCategory.name,
        subcategory: getters.currentCategory.subCat,
        product: getters.currentProduct.title,
        color: getters.currentColor.name,
        image: getters.currentColor.images.mixology,
      }

      let index = state.selectedProducts.indexOf(null)

      if ((index < 0) || (state.indexToRemove >= 0)) {
        if (state.indexToRemove < 0) {
          return
        }
        index = state.indexToRemove
        commit('REMOVE_PRODUCT')
        commit('SET_REMOVE_INDEX', -1)
      }
      commit('ADD_PRODUCT', { index, product })
    },
    updateProductIndeces({ commit, dispatch, state }, { category, product, color }) {
      const categoryIndex = state.productData.findIndex(cat => (cat.subCat ? cat.name + ' ' + cat.subCat : cat.name) === category)
      if (categoryIndex < 0) {
        return
      }
      const productIndex = state.productData[categoryIndex].products.findIndex(prod => prod.title === product)
      if (productIndex < 0) {
        return
      }
      const colorIndex = state.productData[categoryIndex].products[productIndex].colors.findIndex(col => col.name === color)
      if (colorIndex < 0) {
        return
      }
      commit('SET_CATEGORY_INDEX', categoryIndex)
      commit('SET_PRODUCT_INDEX', productIndex)
      commit('SET_COLOR_INDEX', colorIndex)
      dispatch('setNextProduct')
    },
  },
}

export default mixology
