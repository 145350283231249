import Vue from 'vue'
import Router from 'vue-router'
import debug from 'debug'

import globalRoutes from './globalRoutes'
import craftRoutes from './craftRoutes'

const log = debug('router')

/**
 * @function initRouter
 *
 * @param {Array}    routes             Array of routes
 * @param {Store}    store              Instance of Vuex Store
 * @param {Object}   options            Optional parameters
 * @param {Function} options.beforeEach Function that tells router what to do before each route navigation
 */
export default function initRouter(routes, store, options = {}) {
  Vue.use(Router)

  // Craft Preview: https://craftcms.stackexchange.com/questions/22045/live-preview-using-vue-js/31039
  let hashes = window.location.search
    .slice(window.location.search.indexOf('?') + 1)
    .split('&')
  let params = {}
  hashes.map((hash) => {
    let [key, val] = hash.split('=')
    params[key] = decodeURIComponent(val)
  })

  const craftPreview = params['x-craft-preview']
  const craftToken = params['token']

  store.commit('SET_CRAFT_PREVIEW', craftPreview)
  store.commit('SET_CRAFT_TOKEN', craftToken)

  const router = new Router({
    // mode: craftPreview ? 'abstract' : 'history',
    mode: 'history',
    base: '/',
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return {
          selector: to.hash,
        }
      } else if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    },
    routes: [...craftRoutes, ...routes, ...globalRoutes],
  })

  if (options.beforeEach) {
    router.beforeEach(options.beforeEach)
  }

  log('Initialized Router')

  return router
}
