export const stateOptions = [
  {
    value: '',
    label: 'None',
  },
  {
    value: 'AL',
    label: 'Alabama',
  },
  {
    value: 'AB',
    label: 'Alberta',
    country: 'CA',
  },
  {
    value: 'AK',
    label: 'Alaska',
  },
  {
    value: 'AZ',
    label: 'Arizona',
  },
  {
    value: 'AR',
    label: 'Arkansas',
  },
  {
    value: 'BC',
    label: 'British Columbia',
    country: 'CA',
  },
  {
    value: 'CA',
    label: 'California',
  },
  {
    value: 'CO',
    label: 'Colorado',
  },
  {
    value: 'CT',
    label: 'Connecticut',
  },
  {
    value: 'DE',
    label: 'Delaware',
  },
  {
    value: 'DC',
    label: 'District of Columbia',
  },
  {
    value: 'FL',
    label: 'Florida',
  },
  {
    value: 'GA',
    label: 'Georgia',
  },
  {
    value: 'HI',
    label: 'Hawaii',
  },
  {
    value: 'ID',
    label: 'Idaho',
  },
  {
    value: 'IL',
    label: 'Illinois',
  },
  {
    value: 'IN',
    label: 'Indiana',
  },
  {
    value: 'IA',
    label: 'Iowa',
  },
  {
    value: 'KS',
    label: 'Kansas',
  },
  {
    value: 'KY',
    label: 'Kentucky',
  },
  {
    value: 'LA',
    label: 'Louisiana',
  },
  {
    value: 'ME',
    label: 'Maine',
  },
  {
    value: 'MB',
    label: 'Manitoba',
    country: 'CA',
  },
  {
    value: 'MD',
    label: 'Maryland',
  },
  {
    value: 'MA',
    label: 'Massachusetts',
  },
  {
    value: 'MI',
    label: 'Michigan',
  },
  {
    value: 'MN',
    label: 'Minnesota',
  },
  {
    value: 'MS',
    label: 'Mississippi',
  },
  {
    value: 'MO',
    label: 'Missouri',
  },
  {
    value: 'MT',
    label: 'Montana',
  },
  {
    value: 'NE',
    label: 'Nebraska',
  },
  {
    value: 'NV',
    label: 'Nevada',
  },
  {
    value: 'NB',
    label: 'New Brunswick',
    country: 'CA',
  },
  {
    value: 'NH',
    label: 'New Hampshire',
  },
  {
    value: 'NJ',
    label: 'New Jersey',
  },
  {
    value: 'NM',
    label: 'New Mexico',
  },
  {
    value: 'NY',
    label: 'New York',
  },
  {
    value: 'NL',
    label: 'Newfoundland and Labrador',
    country: 'CA',
  },
  {
    value: 'NC',
    label: 'North Carolina',
  },
  {
    value: 'ND',
    label: 'North Dakota',
  },
  {
    value: 'NT',
    label: 'Northwest Territories',
    country: 'CA',
  },
  {
    value: 'NS',
    label: 'Nova Scotia',
    country: 'CA',
  },
  {
    value: 'NU',
    label: 'Nunavut',
    country: 'CA',
  },
  {
    value: 'OH',
    label: 'Ohio',
  },
  {
    value: 'OK',
    label: 'Oklahoma',
  },
  {
    value: 'ON',
    label: 'Ontario',
    country: 'CA',
  },
  {
    value: 'OR',
    label: 'Oregon',
  },
  {
    value: 'PA',
    label: 'Pennsylvania',
  },
  {
    value: 'PE',
    label: 'Prince Edward Island',
    country: 'CA',
  },
  {
    value: 'QC',
    label: 'Quebec',
    country: 'CA',
  },
  {
    value: 'RI',
    label: 'Rhode Island',
  },
  {
    value: 'SK',
    label: 'Saskatchewan',
    country: 'CA',
  },
  {
    value: 'SC',
    label: 'South Carolina',
  },
  {
    value: 'SD',
    label: 'South Dakota',
  },
  {
    value: 'TN',
    label: 'Tennessee',
  },
  {
    value: 'TX',
    label: 'Texas',
  },
  {
    value: 'UT',
    label: 'Utah',
  },
  {
    value: 'VT',
    label: 'Vermont',
  },
  {
    value: 'VA',
    label: 'Virginia',
  },
  {
    value: 'WA',
    label: 'Washington',
  },
  {
    value: 'WV',
    label: 'West Virginia',
  },
  {
    value: 'WI',
    label: 'Wisconsin',
  },
  {
    value: 'WY',
    label: 'Wyoming',
  },
  {
    value: 'YT',
    label: 'Yukon',
    country: 'CA',
  },
]

export const distanceAbbr = {
  Miles: 'MI',
  Kilometers: 'KI',
}
export const distanceValueOptions = ['5', '10', '25', '50', '100', '150', '200']
export const distanceUnitOptions = ['Miles', 'Kilometers']
export const resultsPerPageOptions = [
  {
    name: '9 per page',
    value: 9,
  },
  {
    name: '12 per page',
    value: 12,
  },
  {
    name: '24 per page',
    value: 24,
  },
]
