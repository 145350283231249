export function first(array = [], defaultVal = null) {
  return array && array.length ? array[0] : defaultVal
}

export function getItem(array = [], index, defaultVal = undefined) {
  if (!array || !array.length || array.length <= index) {
    return defaultVal
  }
  return array[index]
}

export function getProp(object, keys, defaultVal) {
  if (!object || !keys) {
    return defaultVal
  }
  keys = Array.isArray(keys) ? keys : keys.split('.')
  object = object[keys[0]]
  if (object && keys.length > 1) {
    return getProp(object, keys.slice(1))
  }
  return object === undefined ? defaultVal : object
}

export function cloneArray(arr) {
  return JSON.parse(JSON.stringify(arr))
}

export function sleep(ms) {
  const date = Date.now()
  let currentDate = null
  do {
    currentDate = Date.now()
  } while (currentDate - date < ms)
}

export function degreesToRadians(degrees) {
  var pi = Math.PI
  return degrees * (pi / 180)
}

export function getDistance(centerLat, centerLng, lat, lng, units = 3959) {
  return (
    units *
    Math.acos(
      Math.cos(degreesToRadians(centerLat)) *
        Math.cos(degreesToRadians(lat)) *
        Math.cos(degreesToRadians(lng) - degreesToRadians(centerLng)) +
        Math.sin(degreesToRadians(centerLat)) * Math.sin(degreesToRadians(lat))
    )
  )
}

export function parseAsHtmlTrademark(str) {
  if (!str) {
    return str
  }
  const strSplit = str.split('®')

  if (strSplit.length <= 1) {
    return str
  }
  const newMark = '<span class="registerMark">®</span>'
  return `${strSplit[0]} ${newMark} ${strSplit[1]}`
}

export default {
  cloneArray,
  degreesToRadians,
  first,
  getDistance,
  getItem,
  getProp,
  sleep,
}
