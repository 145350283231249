export default [
  {
    path: '/',
    name: 'home',
    meta: {
      handledByCraft: true,
    },
  },
  {
    path: '/blog',
    name: 'blog-index',
    meta: {
      handledByCraft: true,
    },
  },
  {
    path: '/blog/:slug',
    name: 'blog-post',
    meta: {
      handledByCraft: true,
    },
  },
  {
    path: '/products',
    name: 'products',
    meta: {
      handledByCraft: true,
    },
  },
]
