<template>
  <nav
    class="navigation"
    :class="{
      'is-active': menuOpen,
      'is-search-active': searchOpen,
      'is-fixed': isFixed,
      'is-fixing': isFixing,
    }"
    id="navigation"
    ref="navigation"
  >
    <a :href="navigation.siteUrl">
      <picture>
        <source
          media="(max-width: 980px)"
          :srcset="require('@/assets/images/cmines-logo-mobile.svg')"
        />
        <img
          class="navigation__logo"
          :src="require('@/assets/images/cmines-logo.svg')"
          :alt="navigation.siteName"
        />
      </picture>
    </a>
    <div class="navigation__menu-wrapper" id="main-navigation">
      <ul class="navigation__menu-list">
        <li v-for="(menuItem, index) of navEntries" :key="`navEntry-${index}`">
          <cta v-bind="{ ...menuItem }" class="navigation__menu-item" />
        </li>
      </ul>
      <div class="navigation__buttons-wrapper">
        <cta-new
          v-for="(menuItem, index) of navCTAs"
          v-bind="{
            ...menuItem,
            size: 's',
            color: 'alternative',
            noArrow: true,
            variant: menuItem.isCTA,
          }"
          :key="`navCTA-${index}`"
          class="c-cta--nw"
        />
      </div>
    </div>

    <form class="navigation__search-wrapper" action="/search">
      <input-text
        class="navigation__search-input"
        :placeholder="'Search'"
        :name="'q'"
      />
    </form>
    <button
      class="navigation__button-icon navigation__search"
      aria-label="Toggle Search Bar"
      @click="toggleSearch"
    >
      <SearchIcon></SearchIcon>
      <CloseIcon></CloseIcon>
    </button>

    <button
      class="navigation__button-icon navigation__hamburger"
      @click="toggleMenu"
    >
      <HamburgerIcon></HamburgerIcon>
      <CloseIcon></CloseIcon>
    </button>
  </nav>
</template>

<script>
import { throttle } from 'lodash-es'
import debug from 'debug'
import { mapActions, mapState, mapMutations } from 'vuex'
import SearchIcon from '@/components/Atoms/SearchIcon'
import CloseIcon from '@/components/Atoms/CloseIcon'
import HamburgerIcon from '@/components/Atoms/HamburgerIcon'
import CtaNew from '@/components/CtaNew'
import Cta from '@/components/Cta'
import InputText from '@/components/Atoms/InputText'

let log = debug('component:Navigation')
const tolerance = 10

export default {
  name: 'navigation',
  components: {
    CloseIcon,
    HamburgerIcon,
    SearchIcon,
    CtaNew,
    Cta,
    InputText,
  },
  data: () => ({
    searchInput: '',
    offset: 0,
    isFixed: false,
    isFixing: false,
  }),
  computed: {
    ...mapState({
      isMobile: 'isMobile',
      loading: 'loading',
      navigation: 'nav',
    }),
    ...mapState('ui', ['menuOpen', 'searchOpen']),
    navEntries() {
      return this.navigation?.nav?.filter((el) => el.isCTA === 'no')
    },
    navCTAs() {
      return this.navigation?.nav
        ?.filter((el) => el.isCTA !== 'no')
        ?.map((el) => ({ ...el, copy: el.copy.toUpperCase() }))
    },
  },
  methods: {
    ...mapActions('ui', ['setMenuOpen', 'setSearchOpen']),
    closeSearch(e) {
      if (this.searchOpen && !this.$refs.navigation.contains(e.target)) {
        log('Close search')
        this.setSearchOpen(false)
      }
    },
    onSearchClick(e) {
      e.stopPropagation()
      if (this.searchInput && this.searchOpen) {
        this.$router.push(`/search?q=${this.searchInput}`)
        this.searchInput = ''
      } else {
        this.toggleSearch()
        if (this.searchOpen) {
          this.$nextTick(function () {
            this.$refs.searchInput.focus()
          })
        }
      }
    },
    toggleMenu() {
      this.setMenuOpen(!this.menuOpen)
    },
    toggleSearch() {
      this.setSearchOpen(!this.searchOpen)
    },
    setupScrollDetect() {
      this.manageScrollThrottled = throttle(this.manageScroll, 100)
      window.addEventListener('scroll', this.manageScrollThrottled)
      this.manageScroll()
    },
    removeScrollDetect() {
      window.removeEventListener('scroll', this.manageScrollThrottled)
    },
    manageScroll() {
      if (this.menuOpen) {
        return
      }

      if (window.scrollY > this.offset && !this.isFixed) {
        this.isFixing = true
      } else if (window.scrollY < tolerance) {
        this.isFixed = false
        this.isFixing = false
      }
    },
    onFixingTransitionEnd() {
      if (this.isFixing) {
        this.isFixed = true
        this.isFixing = false
      }
    },
  },
  mounted() {
    log('Mounted')
    // setup sticky menu
    this.offset = this.$refs.navigation.getBoundingClientRect().height
    // setup close via clickOutside
    const app = document.querySelector('#app')
    app.addEventListener('click', this.closeSearch)
    // ensure buttons are visible
    const html = document.querySelector('html')
    html.classList.add('togglr')
    // setup scroll interactions
    this.setupScrollDetect()
    this.$refs.navigation.addEventListener(
      'transitionend',
      this.onFixingTransitionEnd,
      true
    )
  },
  beforeDestroy() {
    // remove all event listeners
    this.removeScrollDetect()
    const app = document.querySelector('#app')
    app.removeEventListener('click', this.closeSearch)
    this.$refs.navigation.removeEventListener(
      'transitionend',
      this.onFixingTransitionEnd,
      true
    )
  },
}
</script>

<style scoped lang="scss">
// reset bleeding global styles
* {
  font-family: $baseFontFamily;
  line-height: $baseLineHeight;
}

// load craft styles
@import '@craft/styles/_vue';
@import '@craft/styles/05-components/_navigation';

// overrides due to vue app global styles
.navigation__menu-item::v-deep a {
  @extend .navigation__menu-item;
}

.navigation__buttons-wrapper {
  a:hover {
    color: $brand-orange !important;
  }
}
</style>
