import debug from 'debug'

import {
  stateOptions,
  distanceAbbr,
  distanceUnitOptions,
  distanceValueOptions,
  resultsPerPageOptions,
} from '../data/mock/storeLocatorData'
import { locationData } from '../data/mock/locationData'
import { getDistance } from '../../util/helpers'
import { EventBus } from '../../util/eventBus'

let log = debug('store:storeLocator')

const storeLocator = {
  strict: true,
  namespaced: true,

  state: {
    activePage: 0,
    distanceAbbr,
    distanceUnitOptions,
    distanceValueOptions,
    // locations: [],
    // locations: locationData,
    resultsPerPage: resultsPerPageOptions[0],
    resultsPerPageOptions,
    results: [],
    searchParams: {
      address: '',
      state: null,
      distanceValue: '200',
      distanceUnit: 'Miles',
    },
    stateOptions,
  },

  mutations: {
    SET_ACTIVE_PAGE(state, newPage) {
      state.activePage = newPage
    },
    SET_RESULTS(state, results = []) {
      state.results = results
      if (state.searchParams.address)
        state.results.sort((a, b) => {
          return a.distance - b.distance
        })
      else {
        state.results.sort((a, b) => {
          return a.name < b.name ? -1 : 1
        })
      }
      log(state.results)
    },
    SET_RESULTS_PER_PAGE(state, value) {
      state.resultsPerPage = value
    },
    UPDATE_SEARCH_PARAM(state, { key, value }) {
      state.searchParams[key] = value
    },
  },

  getters: {
    numPages(state) {
      if (
        !state.results.length ||
        state.results.length <= state.resultsPerPage.value
      ) {
        return 0
      }
      return Math.ceil(state.results.length / state.resultsPerPage.value)
    },
    getAddress: _ => location => {
      return `${location.street}, ${location.city}, ${location.state} ${location.zip}`
    },
  },

  actions: {
    setActivePage({ commit, getters }, index) {
      if ((index >= 0 && index < getters.numPages) || !getters.numPages) {
        commit('SET_ACTIVE_PAGE', index)
        EventBus.$emit('storeLocator:updateMap')
      }
    },
    setResultsPerPage({ commit, dispatch }, value) {
      commit('SET_RESULTS_PER_PAGE', value)
      dispatch('setActivePage', 0)
    },
    updateDistances({ state, rootState, commit }, { centerLat, centerLng }) {
      const units = state.searchParams.distanceUnit === 'Miles' ? 3959 : 6371
      for (let index = 0; index < rootState.locations.length; index++) {
        const location = rootState.locations[index]
        const distance = getDistance(
          centerLat,
          centerLng,
          location.latitude,
          location.longitude,
          units
        )
        commit('SET_LOCATION_DISTANCE', { index, distance }, { root: true })
      }
      const results = rootState.locations.filter(
        location => location.distance < state.searchParams.distanceValue
      )
      commit('SET_RESULTS', results)
    },
  },
}

export default storeLocator
